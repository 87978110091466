const page = (eventName: string) => {
  window.analytics?.page(eventName);
};

const identify = (eventName: string, data: object) => {
  // This line ensures that a single digit is allowed on Amplitude
  // See: https://amplitude.com/docs/apis/analytics/http-v2#device-ids-and-user-ids-minimum-length
  data['min_id_length'] = 1
  window.analytics?.identify(eventName, data);
};

const track = (eventName: string, data: object) => {
  // This line ensures that a single digit is allowed on Amplitude
  // See: https://amplitude.com/docs/apis/analytics/http-v2#device-ids-and-user-ids-minimum-length
  data['min_id_length'] = 1
  window.analytics?.track(eventName, data);
};

export const analytics = {
  identify,
  page,
  track,

  landingPageViewed(data: object) {
    track('Landing Page Viewed', data);
  },

  userSignedUp(data: object) {
    track('User Signed Up', data);
  },

  userLoggedIn(data: object) {
    track('User Logged In', data);
  },

  userLoggedInAuto(data: object) {
    track('User Logged In (Autho)', data);
  },

  userLoggedOut(data: object) {
    track('User Logged Out', data);
  },

  userLoggedOutAuto(data: object) {
    track('User Logged Out (Auto)', data);
  },

  userSubscriptionAttempted(data: object) {
    track('User Subscription Attempted', data);
  },

  userSubscribed(data: object) {
    track('User Subscribed', data);
  },

  userSubscriptionUpdated(data: object) {
    track('User Subscription Updated', data);
  },

  creatorCreated(data: object) {
    track('Creator Created', data);
  },

  switchedCreator(data: object) {
    track('Switched Creator', data);
  },

  bioLinkUpdated(data: object) {
    track('Bio Link Updated', data);
  },

  smartLinkUpdated(data: object) {
    track('Smart Link Updated', data);
  },

  downloadLinkUpdated(data: object) {
    track('Download Link Updated', data);
  },

  fansExported(data: object) {
    track('Fans Exported', data);
  },

  fansImported(data: object) {
    track('Fans Imported', data);
  },

  campaignCreated(data: object) {
    track('Campaign Created', data);
  },

  campaignUpdated(data: object) {
    track('Campaign Updated', data);
  },

  campaignInstanceCreated(data: object) {
    track('Campaign Instance Created', data);
  },

  campaignInstanceUpdated(data: object) {
    track('Campaign Instance Updated', data);
  },

  projectCreated(data: object) {
    track('Project Created', data);
  },

  projectUpdated(data: object) {
    track('Project Updated', data);
  },

  subprojectCreated(data: object) {
    track('Subproject Created', data);
  },

  subprojectUpdated(data: object) {
    track('Subproject Updated', data);
  },

  taskCreated(data: object) {
    track('Task Created', data);
  },

  taskUpdated(data: object) {
    track('Task Updated', data);
  },

  subtaskCreated(data: object) {
    track('Subtask Created', data);
  },

  subtaskUpdated(data: object) {
    track('Subtask Updated', data);
  },
};

export default analytics;
